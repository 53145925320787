<template>
	<table-page
		v-show="isLoaded"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="categoriesofdis3481"
		tooltipMsg="pimanualofcateg"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'DiscountCategoryId', ascending: 0 }"
		modelId="DiscountCategoryId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
	></table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';

import { tableColumns } from './discount-category-table.data';

export default {
	name: 'DiscountCategoryTable',

	components: {
		TablePage,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'DiscountCategory',
			columns: tableColumns,
		};
	},

	methods: {
		redirectToDetail(id = 0) {
			this.$router.push({
				name: links.discountCategoryDetailName,
				params: { id },
			});
		},
	},
};
</script>