export const tableColumns = [
	{
		model: 'DiscountCategoryId',
		i18n: 'id2541',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'DiscountCategoryNameSk',
		i18n: 'namesk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DiscountCategoryNameCz',
		i18n: 'namecz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DiscountCategoryNamePl',
		i18n: 'namepl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DiscountCategoryNameEn',
		i18n: 'nameen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DiscountCategoryNameDe',
		i18n: 'namede',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DiscountCategoryNameHu',
		i18n: 'namehu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
];
